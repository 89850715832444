import React from 'react';
import {
  ArrayInput,
  Edit,
  SaveButton,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
  Toolbar,
} from 'react-admin';

const IdentityProviderEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);

const IdentityProviderEdit: React.FC<any> = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<IdentityProviderEditToolbar />}>
        <TextField source="userId" />

        <ArrayInput source="domains">
          <SimpleFormIterator>
            <TextInput source="" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default IdentityProviderEdit;
