import { Datagrid, Filter, List, TextField, TextInput } from 'react-admin';
import React from 'react';

const AddonFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search (user id)" source="userId" alwaysOn />
  </Filter>
);

export const IdentityProviderList = (props) => {
  return (
    <List {...props} bulkActionButtons={false} filters={<AddonFilter />}>
      <Datagrid rowClick="edit">
        <TextField source="type" />
        <TextField source="userId" />
        <TextField source="loginUrl" />
        <TextField
          source="fusionIdentityProviderId"
          label="Fusion Identity Provider ID"
        />
        <TextField source="fusionKeyId" label="Fusion Key ID" />
        <TextField source="authorizationType" />
        <TextField source="domains" />
      </Datagrid>
    </List>
  );
};
