import React from 'react';
import {
  List,
  Datagrid,
  FunctionField,
  SelectInput,
  DateInput,
  TopToolbar,
  FilterForm,
  FilterButton,
  ExportButton,
  Button,
} from 'react-admin';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { PaymentsRequest } from './types';
import BulkAcceptButtonList from './BulkAcceptButton';
import { StatusLabels, STATUS_OPTIONS, TypesLabels } from './constants';
import Box from '@material-ui/core/Box';
import IconToc from '@material-ui/icons/Toc';
import { useLocation } from 'react-router-dom';
import toPairs from 'lodash/fp/toPairs';

const filters = [
  <SelectInput
    alwaysOn
    source="status"
    label="Статус заявки"
    choices={STATUS_OPTIONS}
    key="search"
  />,
  <DateInput
    label="Дата создания от"
    source="from"
    defaultValue={format(startOfMonth(new Date()), 'yyyy-MM-dd')}
    key="from"
  />,
  <DateInput
    label="Дата создания по"
    source="to"
    defaultValue={format(endOfMonth(new Date()), 'yyyy-MM-dd')}
    key="to"
  />,
];

const ListActions: React.FC<any> = () => {
  const location = useLocation();

  const handleExport = () => {
    const { search } = location;

    const filterData = JSON.parse(
      decodeURIComponent(search.match(/filter=(.*?)[&$]/)?.[1] ?? '{}'),
    );

    const searchString = toPairs(filterData)
      .map(
        (pair, index) =>
          `filter[${index}]=${encodeURIComponent(
            pair[0] + '||cont||' + pair[1],
          )}`,
      )
      .join('&');

    window.open(
      `${process.env.REACT_APP_API_HOST}/payment_request/export?${searchString}`,
    );
  };

  return (
    <TopToolbar className="filtered-header">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
      >
        <FilterForm filters={filters} />
        <div>
          <FilterButton filters={filters} />
          <ExportButton />
          <Button
            label="Export to XLSX"
            startIcon={<IconToc />}
            onClick={handleExport}
          />
        </div>
      </Box>
    </TopToolbar>
  );
};

const PaymentsRequestsList = (props) => {
  const getNameField = (record: PaymentsRequest): string | undefined =>
    record.profileType === 'legalEntity'
      ? record.fullOrganisationName
      : record.fullName;

  return (
    <List
      {...props}
      perPage={25}
      sort={{ field: 'modified', order: 'DESC' }}
      bulkActionButtons={
        <BulkAcceptButtonList
          basePath={props.basePath}
          resource={props.resource}
          filterValues={props.filterValues}
          selectedIds={props.selectedIds}
        />
      }
      actions={<ListActions />}
    >
      <Datagrid rowClick="show">
        <FunctionField
          label="Вид лица"
          source="profileType"
          // @ts-expect-error
          render={(record: PaymentsRequest) =>
            `${TypesLabels[record.profileType]}`
          }
        />

        <FunctionField
          label="Имя"
          // @ts-expect-error
          render={getNameField}
        />
        <FunctionField
          label="Статус"
          // @ts-expect-error
          render={(record: PaymentsRequest) => `${StatusLabels[record.status]}`}
        />
        <FunctionField
          label="Дата создания заявки"
          source="modified"
          // @ts-expect-error
          render={
            (record: PaymentsRequest) =>
              record.modified &&
              format(new Date(record.modified * 1000), 'dd.MM.yyyy')
            // format(new Date(parseInt(record.created, 10) * 1000),'dd.MM.yyyy')
          }
        />
      </Datagrid>
    </List>
  );
};

export default PaymentsRequestsList;
